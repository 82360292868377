import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {brandUrl} from "../../Urls";
import {useStoreActions} from "easy-peasy";

export default function GlobalSearchHeader({selectAllHandler,clearSelectionHandler,downloadHandler}){

    return (
        <div className={'brand-header'}>

            <a href={'/'}><img src={'/images/left-chevron-dark-blue.svg'} />Back to all Brands</a>

            <div className={'content'}>
                <div className={'logo-holder'}>
                    <span><strong>Atlantic</strong> Asset Library</span>
                </div>
                <div className={'buttons-holder'}>
                    <button onClick={()=>{selectAllHandler()}} className={'all'} >Select All</button>
                    <button onClick={()=>{clearSelectionHandler()}} className={'none'} >Select None</button>
                    <button onClick={()=>{downloadHandler()}} className={'blue'} >Download</button>
                </div>
            </div>
        </div>
    );
}
