import React from 'react'

export default function CategoryListItem({brand,category}){

    return (
        <a href={'/'+ brand.product_brand_slug + '/' + category.product_category_id} className={'category-list-item'}>
            <span>{category.products}</span>
            {brand.product_brand_title + ' ' + category.product_category_title}
        </a>
    );
}
