import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import axios from 'axios';
import {store} from "./store";
import './scss/App.scss';
import {StoreProvider} from "easy-peasy";
import UserBar from "./components/UserBar";
import TopNav from "./components/TopNav";
import BrandsListScreen from "./screens/BrandsListScreen";
import CategoryItemsScreen from "./screens/CategoryItemsScreen";
import ItemScreen from "./screens/ItemScreen";
import {homeUrl} from "./Urls";
import GlobalSearchScreen from "./screens/GlobalSearchScreen";

function App() {

    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(function(response){
        return response;
    }, function(error){
        if(error.response.status == 401){
            window.location.href = homeUrl;
        }
        return Promise.reject(error);
    });
  return (
      <StoreProvider store={store} >
          <UserBar/>
        <Router>
            <div className={'main-content'}>
            <TopNav/>
              <Switch>
                <Route exact path='/'>
                    <BrandsListScreen />
                </Route>
                  <Route path='/search/:searchMatch'>
                      <GlobalSearchScreen />
                  </Route>
                <Route path='/:brandSlug/:categoryId/:itemId'>
                  <ItemScreen />
                </Route>
                <Route path='/:brandSlug/:categoryId'>
                    <CategoryItemsScreen />
                </Route>

                <Route path='*'>
                  <BrandsListScreen />
                </Route>
              </Switch>
            </div>
        </Router>
      </StoreProvider>
  );
}

export default App;
