import React, {useEffect, useState} from 'react'
import {useParams, Redirect} from 'react-router-dom';
import SearchBar from "../components/SearchBar";
import MultiSelectFilter from "../components/MultiSelectFilter";
import SingleSelectFilter from "../components/SingleSelectFilter";
import ItemCard from "../components/CategoryItemsScreen/ItemCard";
import axios from 'axios';
import {bulkDownload, categoryItemsUrl, globalSearchUrl} from "../Urls";
import Spinner from "../components/loadingSpinner";
import {useStoreActions} from "easy-peasy";
import BrandHeader from "../components/CategoryItemsScreen/BrandHeader";
import GlobalSearchHeader from "../components/CategoryItemsScreen/GlobalSearchHeader";

export default function GlobalSearchScreen(){

    const {searchMatch} = useParams();
    const [products,setProducts] = useState([]);
    const [filteredProducts,setFilteredProducts] = useState([]);
    const [subCategories,setSubCategories] = useState([]);
    const [selectedSubCategories,setSelectedSubCategories] = useState([]);
    const [stillLoading,setStillLoading] = useState(true);
    const [searchTerm,setSearchTerm] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);

    const setActiveCategory = useStoreActions(actions=>actions.setCategory);
    const setActiveProduct = useStoreActions(actions=>actions.setProduct);

    const sortOptions = [
        ['name-asc','Name A-Z'],
        ['name-desc','Name Z-A']
    ];
    useEffect(()=>{
        setActiveProduct();
        setActiveCategory();
        setStillLoading(true);
        axios.get(globalSearchUrl+searchMatch).then(response=>{
            let newSubCategories = response.data.sub_categories;
            let newProducts = response.data.items.map(product=>{
                product.sub_assets = [];
                newSubCategories.forEach(subCat=>{
                    if(product.product_category_id === subCat.product_category_id){
                        if(typeof subCat.files !== 'array'){
                            subCat.files = Object.values(subCat.files);
                        }
                        product.sub_assets = subCat.files;
                    }
                });
                product['total_files_count'] += product.sub_assets.length;
                return product;
            });
            setProducts(newProducts.filter(product=>product.total_files_count>0));
            setSubCategories(newSubCategories);
            setStillLoading(false);
        }).catch(e=>{console.log(e)})
    },[searchMatch]);

    useEffect(()=>{
        setFilteredProducts(products);
    },[products]);

    useEffect(()=>{

        setFilteredProducts(products.filter(product=>{
            return (
                (selectedSubCategories.includes(product.product_category_id) || selectedSubCategories.includes(product.top_category) || selectedSubCategories.length===0)
            )
        }));
    },[selectedSubCategories]);


    const getBulkDownload = () =>{
        if(selectedProducts.length<=0){
            return;
        }
        let queryString = '?';
        selectedProducts.forEach((id,index)=>{
            queryString += 'product[]=' + id;
            if(index<selectedProducts.length){
                queryString += '&'
            }
        })
        axios.get(bulkDownload+queryString).then(response=>{
            const url = response.data.file;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'todo-1.json';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(e=>{console.log(e)})
        alert('Bulk Downloads may take a long time to process. You can check their status in the "Downloads" list at the top right of the page.')
    };

    const sortChangeHandler = (selectedSortOption) => {
        let newFilteredProducts = [...filteredProducts];
        switch(selectedSortOption){
            case 'name-asc':
                newFilteredProducts.sort((a, b)=>{
                    if(a.product_title.toLowerCase() < b.product_title.toLowerCase()) { return -1; }
                    if(a.product_title.toLowerCase() > b.product_title.toLowerCase()) { return 1; }
                    return 0;
                });
                break;
            case 'name-desc':
                newFilteredProducts.sort((a, b)=>{
                    if(a.product_title.toLowerCase() > b.product_title.toLowerCase()) { return -1; }
                    if(a.product_title.toLowerCase() < b.product_title.toLowerCase()) { return 1; }
                    return 0;
                });
                break;
            default:
                newFilteredProducts.sort((a, b)=>{
                    if(a.product_id < b.product_id) { return -1; }
                    if(a.product_id > b.product_id) { return 1; }
                    return 0;
                });
                break;
        }
        setFilteredProducts(newFilteredProducts)
    };

    const toggleItemSelected = (itemId) =>{
        let newSelected = [...selectedProducts];
        if(newSelected.includes(itemId)){
            newSelected.splice(newSelected.indexOf(itemId),1);
        }else{
            newSelected.push(itemId);
        }
        setSelectedProducts(newSelected);
    };

    const selectAllProducts = () =>{
        setSelectedProducts(filteredProducts.map(product=>product.product_id))
    };
    const clearSelectedProducts = () =>{
        setSelectedProducts([]);
    };
    return (
        <div className={'brand-items-screen'}>
            <GlobalSearchHeader
                selectAllHandler={selectAllProducts}
                clearSelectionHandler={clearSelectedProducts}
                downloadHandler={getBulkDownload}
            />
            <div className={'search-and-filter-row'}>
                <SearchBar  submitOnEnter={true} submitHandler={setSearchTerm} />
                {searchTerm && <Redirect to={'/search/'+searchTerm} />}
                {subCategories.length > 0 &&
                    <MultiSelectFilter options={subCategories} changeHandler={setSelectedSubCategories}/>
                }
            </div>
            <div className={'sort-row'}>
                <SingleSelectFilter options={sortOptions} changeHandler={sortChangeHandler} />
                {products.length > 0 &&
                    <span>{filteredProducts.length} Results</span>
                }
            </div>
            {stillLoading && <Spinner/>}
            {!stillLoading &&
                <div className={'results-holder'}>
                    {filteredProducts.map((product) => {
                        return (
                            <ItemCard toggleSelectHandler={toggleItemSelected} key={product.product_id} item={product} selected={selectedProducts.includes(product.product_id)}/>
                        )
                    })}
                    {filteredProducts.length === 0 &&
                    <span className={'empty-result-message'}>No Matching Products Found</span>}
                </div>
            }
        </div>
    );
}
