import {createStore, action, persist} from "easy-peasy";

export const store = createStore(persist({
    user:{
        displayName:'',
        setDisplayName:action((state,payload)=>{
            state.displayName = payload;
        })
    },
    brands:[],
    setBrands:action((state,payload)=>{
        state.brands = payload;
    }),
    activeBrand:null,
    setBrand:action((state,payload)=>{
        state.activeBrand = payload || null;
    }),
    activeCategory:null,
    setCategory:action((state,payload)=>{
        state.activeCategory = payload || null;
    }),
    activeProduct:null,
    setProduct:action((state,payload)=>{
        state.activeProduct = payload || null;
    })
}));

