import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom';
import {useStoreActions, useStoreState} from "easy-peasy";
import BrandContainer from "../components/BrandsListScreen/BrandContainer";
import CategoryListItem from "../components/BrandsListScreen/CategoryListItem";
import Spinner from "../components/loadingSpinner";
import axios from "axios";
import {brandListUrl} from "../Urls";
import SearchBar from "../components/SearchBar";

export default function BrandsListScreen(){

    const brands = useStoreState(state=>state.brands);
    const setBrands = useStoreActions(actions=>actions.setBrands);
    const [stillLoading,setStillLoading] = useState(brands?brands.length===0:true);
    const setActiveCategory = useStoreActions(actions=>actions.setCategory);
    const [searchTerm,setSearchTerm] = useState(null);
    useEffect(()=>{
        setActiveCategory();
        let cancelToken = axios.CancelToken.source();
        axios.get(brandListUrl, {cancelToken:cancelToken.token}).then(response=>{
            console.log(response)
            let brands = response.data.brands.map(brand=>{

                if(brand.categories && !Array.isArray(brand.categories)){
                    brand.categories = Object.values(brand.categories);
                }
                return brand;
            });


            setBrands(brands);
            setStillLoading(false)
        }).catch(e=>{console.log(e)});

        return ()=>{
            cancelToken.cancel('');
        }
    },[]);


    return (
        <div className={'brands-list-screen'}>
            <div className={'title-header'}>
                <span><strong>Atlantic</strong> Asset Library</span>
                <SearchBar  submitOnEnter={true} submitHandler={setSearchTerm} />
                {searchTerm && <Redirect to={'/search/'+searchTerm} />}
            </div>
            {stillLoading && <Spinner/>}
            {!stillLoading &&
                <div className={'brands-list'}>
                    {brands.map((brand) => {
                        if(brand.categories.length>0){
                            return (
                                <BrandContainer key={brand.product_brand_id} brand={brand}>
                                    {brand.categories.map((category) => {
                                        if (category.products > 0) {
                                            return (
                                                <CategoryListItem key={category.product_category_id} brand={brand} category={category}/>
                                            )
                                        }
                                        return null;
                                    })}
                                </BrandContainer>
                            )
                        }
                        return null;
                    })}
                </div>
            }
        </div>
    );
}
