import React from 'react'
import {productFiles} from "../../Urls";

export default function ItemCard({item, toggleSelectHandler,selected}){
    const link = '/'+item.product_brand_slug+'/'+item.top_category+'/'+item.product_id;
    return (
        <div className={'item-card'}>
            <div className={'actions'}>
                <a href={link}>
                    <img src={'/images/file-icon-blue.svg'} />
                    <span>{item['total_files_count']}</span>
                </a>
                <div>
                    <a target={'_blank'} className={'download-button'} href={productFiles+item.product_id} download />
                    <span onClick={()=>{toggleSelectHandler(item.product_id)}} className={'check-button ' + (selected?'active':'')}/>
                </div>
            </div>
            <a href={link} className={'main-image-holder'}>
                <img src={item.image} />
            </a>
            <div className={'info-holder'}>
                <div><span>Brand</span><span>{item.product_brand_title}</span></div>
                <div><span>Model</span><span>{item.product_model_number}</span></div>
                <div><span>Item Number</span><span>{item.product_item_number}</span></div>
                <div><span>Description</span><span>{item.product_title}</span></div>

            </div>
        </div>
    );
}
