import React, {useEffect} from 'react';
import {useStoreActions, useStoreState} from 'easy-peasy';
import axios from 'axios';
import {homeUrl, logOut, userInfo} from "../Urls";

export default function UserBar(){

    const userName = useStoreState(state=>state.user.displayName);
    const setDisplayName = useStoreActions(actions=>actions.user.setDisplayName);
    useEffect(()=>{
       if(!userName){
           axios.get(userInfo).then(response=>{
               setDisplayName(response.data.user.name);
           }).catch(e=>{console.log(e)})
       }
    },[]);

    return (
        <div className={'user-bar'}>
            <div>
                <span>Logged in as: {userName} - <a href={logOut}>Log Out</a></span>
                <div className={'link-holder'}>
                    <a href={homeUrl+'/account'}>Account</a>
                    <a href={'/'}>Asset Library</a>
                </div>
            </div>
        </div>

    );
}
