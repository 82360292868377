import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import ItemHeader from "../components/ItemScreen/ItemHeader";
import AssetGallery from "../components/ItemScreen/AssetGallery";
import Drawer from "../components/Drawer";
import ListCard from "../components/ListCard";
import {productUrl} from "../Urls";
import Spinner from "../components/loadingSpinner";
import {useStoreActions} from "easy-peasy";
import RelatedProduct from "../components/ItemScreen/RelatedProduct";

export default function ItemScreen(){

    const {itemId} = useParams();
    const [assets,setAssets] = useState([]);
    const [product,setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [replacementParts, setReplacementParts] = useState([]);
    const [descriptionCard,setDescriptionCard] = useState(null);
    const [specsCard,setSpecsCard] = useState(null);
    const [optionsCard,setOptionsCard] = useState(null);
    const [shippingCard,setShippingCard] = useState(null);
    const [dimensionCard,setDimensionCard] = useState(null);
    const [bulletPointCard,setBulletPointCard] = useState(null);
    const [stillLoading,setStillLoading] = useState(true);
    const [youtubeVideo, setYoutubeVideo] = useState(null);

    const setActiveProduct = useStoreActions(actions=>actions.setProduct);
    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(productUrl+itemId, {cancelToken:cancelToken.token}).then(response=>{
            const resProduct = response.data.product;
            if(typeof resProduct.files !== 'array'){
                resProduct.files = Object.values(resProduct.files);
            }
            if(typeof resProduct.category.files !== 'array'){
                resProduct.category.files = Object.values(resProduct.category.files);
            }
            resProduct.category.files = resProduct.category.files.map(file=>{
                file.product_file_file = file.product_category_file_file;
                delete(file.product_category_file_file);
                file.product_file_title = file.product_category_file_title;
                delete(file.product_category_file_title);
                return file;
            });
            resProduct.files = [...resProduct.files,...resProduct.category.files];
            setAssets(resProduct.files);
            let price = resProduct.list_price? '$'+resProduct.list_price:null;
            setSpecsCard( [
                ['List Price',price],
                ['Description',resProduct.product_title],
                ['UPC Code',resProduct.product_upc]
            ]);

            setRelatedProducts(resProduct.recommended_products);

            setReplacementParts(resProduct.replacement_parts);

            console.log(resProduct);

            setDescriptionCard([
                ['Youtube Link',resProduct.product_youtube_link?'<a href="'+resProduct.product_youtube_link+'" target="_blank" >'+resProduct.product_youtube_link+'</a>':null],
                ['Short Description',resProduct.product_short_description],
                ['Long Description',resProduct.product_description],
                ['Related Products',resProduct.related_products.filter(prod=>prod.product_category_id).map(prod=>'<a href="/'+prod.product_brand_slug+'/'+prod.top_category+'/'+prod.product_id+'">'+prod.product_title+'</a>').reduce((prevVal,currVal,idx)=>{
                    return idx === 0 ? currVal : prevVal + '<br/><br/>' + currVal;
                }, '')],
            ]);

            setBulletPointCard([
                ['Bullet Points',resProduct.bullet_points.reduce((prevVal,currVal,idx)=>{
                    return idx === 0 ? currVal : prevVal + '<br/><br/>' + currVal;
                }, '')]
            ])

            setOptionsCard(resProduct.options.map(option=>{
                return [option.product_option_title,option.values.reduce((prevVal,currVal,idx)=>{
                    return idx === 0 ? currVal : prevVal + '<br/><br/>' + currVal;
            }, '')];
            }));

            if(resProduct.shipping_info) {
                setShippingCard(Object.entries(resProduct.shipping_info).map(info => {
                    if (info[1] && info[0].indexOf('_id') < 0) {
                        info[0] = info[0].replace('product_shipping', '').replace(/_/g, ' ');
                        if (['length', 'width', 'height', 'cube'].filter(s => info[0].indexOf(s) > -1).length > 0) {
                            info[1] += '"';
                        } else if (info[0].indexOf('weight') > -1) {
                            info[1] += ' lbs';
                        }
                        return info;
                    }
                    return null;
                }));
            }else{
                setShippingCard(null);
            }

            let dimensionType = '';
            switch(resProduct.product_dimensions){
                case 'depth':
                    dimensionType = 'Width/Depth (WxDxH)';
                    break;
                case 'length/width':
                    dimensionType = 'Length/Width/Height (LxWxH)';
                    break;
                case 'outer-inner':
                    dimensionType = 'Outer/Inner (ODxIDxH)';
                    break;
                default:
                    break;
            }
            setDimensionCard([
                ['Dimensions',dimensionType],
                ['Weight',resProduct.product_weight?resProduct.product_weight+' lbs':null],
                ['Length',resProduct.product_length?resProduct.product_length+'"':null],
                ['Width',resProduct.product_width?resProduct.product_width+'"':null],
                ['Height',resProduct.product_height?resProduct.product_height+'"':null],
                ['Depth / Inner Diameter',resProduct.product_depth?resProduct.product_depth+'"':null],
                ['Outer Diameter',resProduct.product_outer_depth?resProduct.product_outer_depth+'"':null],
            ]);

            setActiveProduct(resProduct);
            setProduct(resProduct);

            if (resProduct.product_youtube_link) {
                const youtubeLink = `https://www.youtube.com/embed/${resProduct.product_youtube_link}?autoplay=0&controls=0&showinfo=0&rel=0`
                setYoutubeVideo(<iframe width="400" height="300"
                                        src={youtubeLink}
                                        frameBorder="0" allowFullScreen></iframe>);
            }

            setStillLoading(false);

        }).catch(e=>{console.log(e)});

        return ()=>{
            cancelToken.cancel('');
        }
    },[itemId, setActiveProduct]);
    return (
        <div className={'item-screen'}>
            {stillLoading && <Spinner/>}
            {!stillLoading &&
            <>
                <ItemHeader product={product} />
                <AssetGallery assets={assets} />
                <div className={'drawers-holder'}>
                <Drawer title={'Product Specs'} open={true}>
                    <ListCard  items={specsCard}/>
                    <ListCard items={optionsCard}/>
                </Drawer>
                    {product &&
                        <Drawer title={'Product Description'} >
                            <ListCard items={descriptionCard}/>
                            <ListCard items={bulletPointCard}/>
                        </Drawer>
                    }

                    {product && product.product_youtube_link && <Drawer title={'Product Video'}>
                        {youtubeVideo}
                    </Drawer>}

                    {
                        relatedProducts.length !== 0 && <Drawer title={'Related Products'} isProducts={true}>
                            {
                                relatedProducts.map((product, i) => {
                                    return <RelatedProduct key={i} product={product} />
                                })
                            }
                        </Drawer>
                    }

                    {product.shipping_info &&
                    <Drawer title={'Dimensions & Shipping Info'} >
                        <ListCard  items={dimensionCard}/>
                        <ListCard  items={shippingCard}/>
                    </Drawer>
                    }

                    {
                        replacementParts.length !== 0 && <Drawer title={'Replacement Parts'} isProducts={true}>
                            {
                                replacementParts.map((product, i) => {
                                    return <RelatedProduct key={i} product={product} />
                                })
                            }
                        </Drawer>
                    }
                </div>
            </>
            }
        </div>
    );
}
