import React, {useState} from 'react'

export default function Drawer({children, title, open, isProducts}){

    const [isOpen,setIsOpen] = useState(!!open)

    return (
        <div className={'drawer ' + (isOpen?'open':'')}>
            <div className={'header'} onClick={()=>{setIsOpen(!isOpen)}}>
                {title}
            </div>
            <div className={isProducts ? 'content-products' : 'content'}>
                {children}
            </div>
        </div>
    );
}
