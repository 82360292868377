import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {checkDownload} from "../Urls";
import Spinner from "./loadingSpinner";

export default function DownloadsPanel(){

    const [downloads,setDownloads] = useState([]);
    const [checkTimer,setCheckTimer] = useState(null);

    const checkDownloads = () =>{
        axios.get(checkDownload).then(res=>{
            if(res?.data?.downloads){
                let newDownloads = res.data.downloads.map(download=>{
                    if(download.user_download_status === 'processing') {
                        let startDate = new Date(download.user_download_timestamp);
                        let endDate = new Date();
                        let hours = (endDate.getTime() - startDate.getTime()) / 3600000;
                        if (hours > 6) {
                            download.user_download_status = 'error';
                        }
                    }
                    return download;
                })
                setDownloads(newDownloads)
            }
        }).catch(e=>{})
    }

    useEffect(()=>{
        if(checkTimer){
            clearInterval(checkTimer)
        }
        checkDownloads();
        setCheckTimer(setInterval(checkDownloads,10000));

        return ()=>{
            if(checkTimer){
                clearInterval(checkTimer)
            }
        }
    },[])

    return (
        <div className={'downloads-panel'}>
            {downloads.length > 0 &&
            <>
                <span>Downloads</span>

                <div className={'download-list'}>
                    <ul>
                        {downloads.map(download =>
                            <li key={download.user_download_id}>
                                <span className={'status ' + download.user_download_status}>
                                    {(download.user_download_status && download.user_download_status === 'processing') &&
                                    <Spinner/>
                                    }
                                </span>
                                {download.user_download_status === 'ready' &&
                                <a href={download.file_url || '#'} download={true}>
                                    <span className={'title'}>{download.user_download_title}</span>
                                    <span
                                        className={'title'}>{new Date(download.user_download_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                                </a>
                                }
                                {download.user_download_status !== 'ready' &&
                                <>
                                    <span className={'title'}>{download.user_download_title}</span>
                                    <span
                                        className={'title'}>{new Date(download.user_download_timestamp.replace(' ','T')).toLocaleDateString()}</span>
                                </>
                                }
                            </li>
                        )}
                    </ul>
                </div>
            </>
            }

        </div>
    )
}