import React from 'react'

export default function RelatedProduct({product}){
    return (
        <div className={'related-product-card'}>
            <img src={product.image} alt={product.product_title + ' image'} />

            <div>
                {product.product_title}<br />

                {product.options.map((option, i) => {
                    if (option.product_option_title.includes('Model')) {
                        return <div key={i}>Model: {option.values[0]} <br /></div>;
                    } else if (option.product_option_title.includes('Item')) {
                        return <div key={i}>Item: {option.values[0]} <br /></div>;
                    }

                    return null;
                })}
            </div>
        </div>
    );
}
