export const homeUrl = window.location.hostname.indexOf('local') > -1
    ? 'http://awg.local'
    : (window.location.hostname.indexOf('dev2') > -1
        ? 'https://www.awg-site.dev2.stream9.net'
        : 'https://www.atlantic-oase.com');
export const brandListUrl = homeUrl+'/api/brands';
export const brandUrl = homeUrl+'/api/brand/';
export const categoryItemsUrl = homeUrl + '/api/category-items/';
export const productUrl = homeUrl + '/api/product/';
export const categoryUrl = homeUrl + '/api/category/';
export const productFiles = homeUrl + '/api/product-files/';
export const singleProductFile = homeUrl + '/api/download-file/';
export const bulkDownload = homeUrl + '/api/bulk-download/';
export const brandDownload = homeUrl + '/api/brand-download/';
export const checkDownload = homeUrl + '/api/get-user-downloads/';
export const userInfo = homeUrl + '/api/user/';
export const logOut = homeUrl + '/api/logout/';
export const globalSearchUrl = homeUrl + '/api/search-all/';
