import React from 'react'
import {useLocation} from 'react-router-dom';
import {homeUrl} from "../Urls";
import {useStoreState} from "easy-peasy";
import DownloadsPanel from "./downloadsPanel";

export default function TopNav(){
    let location = useLocation();
    const brand = useStoreState(state=>state.activeBrand);
    const category = useStoreState(state=>state.activeCategory);
    const product = useStoreState(state=>state.activeProduct);

    return (
        <div className={'top-nav'}>
            <nav>
                <a href={homeUrl}>Home</a>
                <a href={'/'} className={location.pathname.length>1?'':'active'}>All Brands</a>
                {(category && brand) && <a href={'/'+brand.product_brand_slug+'/'+category.product_category_id} className={!product?'active':''}>{category.product_category_title}</a>}
                {(category && brand && product) && <a href={'/'+brand.product_brand_slug+'/'+category.product_category_id+'/'+product.product_id} className={'active'}>{product.product_title}</a>}
                {location.pathname.indexOf('/search/')>-1 && <a className={'active'} href={location.pathname}>Global Search</a>}
            </nav>
            <DownloadsPanel />
        </div>
    );
}
