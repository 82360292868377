import React, {useEffect, useState} from 'react'
import axios from "axios";
import {brandDownload} from "../../Urls";

export default function BrandContainer({brand,children}){
    children = children.filter(child=>child);

    const downloadHandler = () =>{

        axios.get(brandDownload+'?brand_id='+brand.product_brand_id).then(response=>{
            if(response.data.file) {
                const url = response.data.file;
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'bulk-download.json';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).catch(e=>{console.log(e)})
        alert('Bulk Downloads may take a long time to process. You can check their status in the "Downloads" list at the top right of the page.')
    }

    const getNewHeight = () =>{
        let windWidth = window.innerWidth;
        let newHeight =   Math.ceil(children.length/3)*70;
        if(windWidth<=1100 && windWidth>760){
            newHeight = Math.ceil(children.length/2)*70;
        }else if(windWidth<=760){
            newHeight = 'unset';
        }
        return newHeight;
    };
    const [listHeight,setListHeight] = useState(getNewHeight);
    useEffect(()=>{
        function handleResize() {
            setListHeight(getNewHeight())

        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });
    return (
        <>
        {children.length > 0 &&
                <div className={'brand-container'}>
                    <div className={'brand-sub-header'}>
                        <div>
                        {brand.image_url && <img src={brand.image_url}/>}
                        {brand.product_brand_subtitle && <span>{brand.product_brand_subtitle}</span>}
                        </div>
                        <div className={'buttons-holder'}>
                            <button onClick={downloadHandler} className={'blue'} >Download All Brand Files</button>
                        </div>
                    </div>
                    <div className={'category-list'} style={{maxHeight: listHeight}}>
                        {children}
                    </div>
                </div>
        }
        </>
    );
}
