import React from 'react'

export default function ListCard({items,topAlign=false}){
    return (
        <div className={'list-card'}>
            <div className={'info-holder '+(topAlign?'top-align':'')}>
                {items && items.map((item)=>{
                    if(item && item[1] && item[1].trim().length>0) {
                        return (
                            <div key={item[0]}>
                                <span>{item[0]}</span><span dangerouslySetInnerHTML={{ __html: item[1]}} />
                            </div>
                        )
                    }
                    return null;
                })}
            </div>
        </div>
    );
}
