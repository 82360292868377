import React, {useEffect, useState} from "react";

export default function MobileAssetInfo({asset,place}){

    const [showClipboardLink,setShowClipboardLink] = useState(false);

    useEffect(()=>{
        if(navigator.permissions) {
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    setShowClipboardLink(true)
                }
            });
        }else{
            console.log('Copy to Clipboard not allowed');
        }
    },[]);


    const copyLinkToClipboard = ()=>{
        if(navigator.clipboard) {
            navigator.clipboard.writeText(asset.file_url).then(function () {
                /* clipboard successfully set */
            }, function () {
                console.log('Copy to Clipboard not allowed')
            });
        }
    };

    return(
        <div className={'mobile-info'}>
            <div>
                <div className={'asset-header'}>
                    <span>{asset.product_file_type_title} Asset <strong>{place}</strong></span>
                    <div>
                        {showClipboardLink && <span onClick={()=>{copyLinkToClipboard()}} className={'asset-link'} >Asset Link</span>}
                        <a className={'download-button'} href={asset.file_url} target={'_blank'} download={asset.file_url} />
                    </div>
                </div>
                <div className={'info-holder'}>
                    <div><span>File Name</span><span>{asset.product_file_title || 'N/A'}</span></div>
                    <div><span>File Type</span><span>{asset.product_file_type_title || 'N/A'}</span></div>
                    <div><span>Creation Date</span><span>{asset.file_date || 'N/A'}</span></div>
                    <div><span>Dimensions</span><span>{asset.dimensions?asset.dimensions[0]+'x'+asset.dimensions[1] : 'N/A'}</span></div>
                    <div><span>File Size</span><span>{asset.file_size || 'N/A'}</span></div>
                </div>
            </div>
        </div>
    )
}
