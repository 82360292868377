import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import axios from "axios";
import {brandUrl, categoryUrl,productFiles} from "../../Urls";

export default function ItemHeader({product}){

    const {brandSlug,categoryId} = useParams();

    const [logo,setLogo] = useState('');
    const [category,setCategory] = useState({});
    useEffect(()=>{
        axios.get(brandUrl+brandSlug).then(response=>{
            setLogo(response.data.brand.image_url);
        }).catch(e=>{console.log(e)});
        axios.get(categoryUrl+categoryId).then(response=>{
            setCategory(response.data.category)
        }).catch(e=>{console.log(e)})
    },[brandSlug]);


    return (
        <div className={'brand-header product'}>

            <a href={'/'+brandSlug+'/'+categoryId}><img src={'/images/left-chevron-dark-blue.svg'} />Back to all {category?category.product_category_title : ''}</a>

            <div className={'content'}>
                <div className={'logo-holder'}>
                    <a href={'/'+brandSlug+'/'+categoryId} ><img src={'/images/left-chevron-dark-blue.svg'} /></a>
                    <img src={logo}/>
                    <span>{product.product_title}</span>
                </div>
                <div className={'buttons-holder'}>
                    <a href={productFiles+product.product_id} className={'button blue'} >Download All Product Assets</a>
                </div>
            </div>
        </div>
    );

}
