import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {brandUrl} from "../../Urls";
import {useStoreActions} from "easy-peasy";

export default function BrandHeader({brandSlug, selectAllHandler,clearSelectionHandler,downloadHandler}){

    const [logo,setLogo] = useState('');
    const setActiveBrand = useStoreActions(actions=>actions.setBrand);

    useEffect(()=>{
        axios.get(brandUrl+brandSlug).then(response=>{
            setActiveBrand(response.data.brand);
            setLogo(response.data.brand.image_url);
        }).catch(e=>{console.log(e)});

    },[brandSlug]);


    return (
        <div className={'brand-header'}>

            <a href={'/'}><img src={'/images/left-chevron-dark-blue.svg'} />Back to all Brands</a>

            <div className={'content'}>
                <div className={'logo-holder'}>
                    <a href={'#'} ><img src={'/images/left-chevron-dark-blue.svg'} /></a>
                    <img src={logo}/>
                </div>
                <div className={'buttons-holder'}>
                    <button onClick={()=>{selectAllHandler()}} className={'all'} >Select All</button>
                    <button onClick={()=>{clearSelectionHandler()}} className={'none'} >Select None</button>
                    <button onClick={()=>{downloadHandler()}} className={'blue'} >Download</button>
                </div>
            </div>
        </div>
    );
}
