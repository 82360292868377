import React, {useEffect, useState} from 'react'

export default function SearchBar({submitHandler,changeHandler,submitOnEnter=false}){

    const getPlaceholder = () =>{
        if(window.innerWidth<=760){
            return 'Enter Search'
        }
        return 'Enter Search Keywords';
    };

    const [searchTerm,setSearchTerm] = useState('');
    const [placeholder,setPlaceholder] = useState(getPlaceholder());
    const submit = ()=>{
        if(typeof submitHandler === 'function') {
            submitHandler(searchTerm);
        }
    };

    const keyUpHandler = e =>{
        if(e.keyCode === 13){
            submit();
        }
    };

    useEffect(()=>{
        function handleResize() {
            setPlaceholder(getPlaceholder())
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    });
    useEffect(()=>{
        if(!submitOnEnter && typeof changeHandler === 'function') {
            changeHandler(searchTerm.toLowerCase());
        }
    },[submitOnEnter,searchTerm]);

    return (
        <div className={'search-bar'}>
            <input placeholder={placeholder} type={'text'} value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} onKeyUp={e=>{keyUpHandler(e)}} />
            <button onClick={()=>{submit()}} />
        </div>
    );
}
