import React, {useState} from 'react'
import AssetGalleryItem from "./AssetGalleryItem";
import MobileAssetInfo from "./MobileAssetInfo";

export default function AssetGallery({assets}){

    const [activeAsset,setActiveAsset] = useState(0);

    const nextSlide = () =>{
        let next = (activeAsset+1)>(assets.length-1)?0:activeAsset+1;
        setActiveAsset(next)
    };
    const prevSlide = () =>{
        let next = (activeAsset-1)<0?(assets.length-1):activeAsset-1;
        setActiveAsset(next)
    };

    const getPlaceholder = (asset) =>{
        if(!asset.product_file_file){
            return '';
        }
        const extension = asset.product_file_file.split('.').pop();
        switch(extension){
            case 'pdf':
                return '/images/pdf.svg';
            default:
                return '/images/document.svg';

        }
    }
    return (
        <div className={'asset-gallery'}>
            <div className={'gallery-content'}>
                <div className={'top'}>
                    <span onClick={()=>{prevSlide()}} className={'prev-slide'} />
                    <span onClick={()=>{nextSlide()}} className={'next-slide'} />
                    <AssetGalleryItem asset={assets[activeAsset]} place={(activeAsset+1)+'/'+assets.length} />
                </div>
                <div className={'thumbnails '}>
                    {assets.map((asset,index)=> {
                        if (asset.product_file_file) {
                            return (
                                <div key={index} className={'thumbnail-wrapper'}>
                                    <img onClick={() => {setActiveAsset(index)}}
                                     key={index}
                                     className={(index === activeAsset ? 'active ' : '') + (!asset.thumbnail ? 'placeholder ' : '')}
                                     src={asset.thumbnail || getPlaceholder(asset)}/>
                                     <span className={'under-thumb-name'}>{asset.product_file_title} </span>
                                </div>
                            )

                        }
                        return null;
                    })}
                </div>
                <MobileAssetInfo asset={assets[activeAsset]} place={(activeAsset+1)+'/'+assets.length} />
            </div>
        </div>
    );
}
