import React, {useState} from 'react'

export default function MultiSelectFilter({options,changeHandler}){

    const [showPopup,setShowPopup] = useState(false);
    const [oldSelected,setOldSelected] = useState([]);
    const [curSelected,setCurSelected] = useState([]);

    const saveHandler = () =>{
        setOldSelected(curSelected);
        changeHandler(curSelected);
        setShowPopup(false);
    };

    const clickHandler = (e) =>{
        const clickedId = e.target.getAttribute('data-category-id');
        let newSelected = [...curSelected];
        if(newSelected.includes(clickedId)){
            newSelected.splice(newSelected.indexOf(clickedId),1);
        }else{
            newSelected.push(clickedId);
        }
        setCurSelected(newSelected);
    };

    const selectAll = ()=>{
        setCurSelected(options.map(option=>(option.product_category_id)));
    };
    const closeHandler = () =>{
        setCurSelected(oldSelected);
        setShowPopup(false);
    }
    return (
        <>
            {showPopup &&
            <div className='popup-holder'>
                <div className='popup-body filter-popup'>
                    <h2>Filter by Category</h2>
                    <div className={'buttons-holder'}>
                        <button onClick={()=>{selectAll()}} className={'button all'} >Select All</button>
                        <button onClick={()=>{setCurSelected([])}} className={'button none'} >Select None</button>
                        <button onClick={()=>{saveHandler()}} className={'button blue'} >Apply</button>
                    </div>
                    <div className={'options-holder'}>
                        {options.map((option)=>(
                            <div onClick={(e)=>{clickHandler(e)}} key={option.product_category_id} className={'option'} data-category-id={option.product_category_id}>
                                <span onClick={(e)=>{clickHandler(e)}} data-category-id={option.product_category_id} className={'check ' + (curSelected.includes(option.product_category_id)?'selected':'') } />
                                {option.product_category_title}
                            </div>
                        ))}
                    </div>
                    <div className={'centered-row'}>
                        <span onClick={()=>{closeHandler()}} className={'close'}>Close</span>
                    </div>
                </div>
            </div>
            }
            <button onClick={()=>{setShowPopup(true)}} className={'multi-select'}>
                Filter By Category
            </button>
        </>
    );
}
