import React from 'react'

export default function SingleSelectFilter({options,changeHandler}){

    return (
        <select onChange={(e)=>{changeHandler(e.target.value)}} className={'single-select'}>
            <option value={'default'}>Sort By:</option>
            {options.map((option,index)=>(
                <option key={index} value={option[0]}>{option[1]}</option>
            ))}
        </select>
    );
}
